import * as React from "react";
import * as moment from "moment";

interface IProps {
    date: string;
}

const fromNowAsText = (value: string): string => {
    return moment(value, moment.ISO_8601).fromNow();
}

const toLocal = (value: string): string => {
    // Note: LLL = local time fully written
    return moment(value, moment.ISO_8601).format("LLL");
}

export const TimeLabel = React.memo<IProps>((props) =>
(
    <span className="ui remark" data-tooltip={toLocal(props.date)} data-position="bottom center">
        {fromNowAsText(props.date)}
    </span>
));
TimeLabel.displayName = "TimeLabel";
