import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationProvider } from "@/auth";
import { LoadingProvider, LoggingProvider } from "@/contexts";
import { Layout } from "@/pages/Layout";

import "./css/index.g.scss";
import "./css/theme.g.scss";

const rootElement = document.getElementById('root');

ReactDOM.render((
    <BrowserRouter>
        <LoggingProvider>
            <LoadingProvider>
                <AuthenticationProvider>
                    <Layout />
                </AuthenticationProvider>
            </LoadingProvider>
        </LoggingProvider>
    </BrowserRouter>
), rootElement);
