import React from "react";
import { InlineMessage, LoggingPlaceholder } from "@/components/common";
import { ContentPage } from "./ContentPage";
import { useReverseRouter } from "@/hooks";
import { Welcome } from "@/components/navigation";
import { useLoadingContext } from "@/contexts";

import styles from "./css/layout.scss";

export const Layout: React.FC = () => {
    const loadingContext = useLoadingContext();
    const pathName = useReverseRouter().path;

    const warningMessage: React.ReactNode | null = React.useMemo(() => {
        switch (pathName) {
            case "organization-moved":
                return (
                    <span>
                        <span>This URL will be removed in the near future. Please bookmark the following url </span>
                        <span className="clickable clickableText" onClick={() => { window.location.href = "http://app.keeyns.com/" }}>app.keeyns.com</span>
                    </span>
                )
            case "organization-unavailable":
                return (
                    <span>
                        The requested organization is not available.
                    </span>
                )
            case "organization-suspended":
                return (
                    <span>
                        The requested organization is suspended. Contact this organization in case you need access.
                    </span>
                )
            default:
                return null;
        }
    }, [pathName]);

    return (
        <div className={styles.content}>
            <ContentPage>
                {!loadingContext.isLoading && (
                    <div className={styles.warnings}>
                        <div>
                            <LoggingPlaceholder />
                            {warningMessage != null && (
                                <InlineMessage
                                    type="warning"
                                >
                                    {warningMessage}
                                </InlineMessage>
                            )}
                        </div>
                    </div>
                )}
                <Welcome />
                <div />
            </ContentPage>
        </div>
    );
}
