import { Animation } from "@/components/common";
import * as React from "react";

import styles from "./css/ApplicationLoader.scss";

export const ApplicationLoaderPage: React.FC = () => (
    <React.Suspense fallback={<></>}>
        <div className={styles.applicationLoader}>
            <Animation src="/animations/applicationLoader.json" />
        </div>
    </React.Suspense>
);
