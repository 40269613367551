import * as React from "react";

import styles from "./css/EmptyStatesComponent.scss";

export type EmptyStateTypes = "nothingHere" | "questionMark";
interface IEmptyStatesProps {
    imgPath: string,
    title: string
}

export const EmptyStatesComponent: React.FC<IEmptyStatesProps> = (props) => (
    <div className={styles.emptyState}>
        <img src={`/svgs/${props.imgPath}.svg`} />

        <h4>{props.title}</h4>
    </div>
);
