import axios from "axios";
import { useAuthenticationContext } from "@/auth";

export type UseApiHook = <TResource extends object>(baseUrl: string) => UseApiResult<TResource>;
export interface UseApiResult<TResource> {
    get: (id: string | number) => Promise<TResource>;
    list: () => Promise<TResource[]>;
    post: (data: Partial<TResource>) => Promise<TResource>;
    put: (id: string | number, data: Partial<TResource>) => Promise<TResource>;
    upsert: (id: string | number | null | undefined, data: Partial<TResource>) => Promise<TResource>;
    remove: (id: string | number) => Promise<void>;
}

export const useApi: UseApiHook = <TResource extends object>(baseUrl: string) => {
    const { isAuthenticated, token } = useAuthenticationContext();
    const buildSettings = () => {
        if (!isAuthenticated) {
            throw Error("Cannot use the API when not authenticated.");
        }
        return { headers: { authorization: token } };
    };

    const get = (id: string | number) => (
        axios.get<TResource>(baseUrl + "/" + id, buildSettings()).then((result) => (
            result.data
        ))
    );

    const list = () => (
        axios.get<TResource[]>(baseUrl, buildSettings()).then((result) => (
            result.data
        ))
    );

    const post = (data: Partial<TResource>) => (
        axios.post<TResource>(baseUrl, data, buildSettings()).then((result) => (
            result.data
        ))
    );

    const put = (id: string | number, data: Partial<TResource>) => (
        axios.put<TResource>(baseUrl + "/" + id, data, buildSettings()).then((result) => (
            result.data
        ))
    );

    const upsert = (id: string | number | null | undefined, data: Partial<TResource>) => (
        id == null ? post(data) : put(id, data)
    );

    const remove = (id: string | number) => (
        axios.delete(baseUrl + "/" + id, buildSettings()).then(() => undefined)
    );

    return {
        get,
        list,
        post,
        put,
        upsert,
        remove,
    };
};
