import { PortalDto } from "@/services/entities";
import classNames from "classnames";
import React from "react";
import { Icon, Loader, Popup } from "semantic-ui-react";

import styles from "./css/PortalList.scss";

interface IProps {
    portals: PortalDto[];
    setSelectedPortalUrl: (portal: string) => void;
}

export const PortalList: React.FC<IProps> = (props) => (
    <div className={styles.portalList}>
        <div className={styles.list}>
            {props.portals.map((portal, i) => (
                <PortalCard
                    key={i}
                    portal={portal}
                    onClick={() => props.setSelectedPortalUrl(portal.url)}
                />
            ))}
        </div>
    </div>
);

interface PortalCardProps {
    portal: PortalDto;
    onClick: () => void;
}

const PortalCard: React.FC<PortalCardProps> = (props) => {
    const [isClicked, setIsClicked] = React.useState(false);

    const onClick = () => {
        setIsClicked(true);
        props.onClick();
    }

    return (
        <Popup
            content="This organization is suspended"
            position="right center"
            disabled={props.portal.isSuspended !== true}
            trigger={
                <div
                    className={classNames(styles.portalCard, { clickable: (!props.portal.isSuspended && !isClicked) })}
                    onClick={() => { (!props.portal.isSuspended && !isClicked) ? onClick() : null }}
                >
                    <img
                        width={40}
                        height={40}
                        src={props.portal.imageUrl ?? "/images/portal_40x40.png"}
                    />
                    <div className={styles.portalDetails}>
                        <h5>{props.portal.displayName}</h5>
                        <span>
                            {props.portal.url.replace(/^.*?\/\/(.+?)(?:\/|$)/, "$1")}
                        </span>
                    </div>
                    {!isClicked ? (
                        <Icon
                            className={classNames({ [styles.suspended]: props.portal.isSuspended })}
                            name={props.portal.isSuspended ? "ban" : "chevron right"}
                        />
                    ) : (
                        <Loader
                            active={true}
                            inline={true}
                            size="small"
                        />
                    )}
                </div>
            }
        />
    )
}