import { FASemanticICONS } from "@/types";
import classNames from "classnames";
import * as React from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";

import styles from "./css/InlineMessage.scss";

interface IProps {
    message?: React.ReactNode | string | string[] | null;
    type?: MessageType;
    icon?: FASemanticICONS | "hidden";
    className?: string;
    compact?: boolean;
    children?: React.ReactNode;
}

type MessageType = "neutral" | "loading" | "success" | "info" | "warning" | "error";

const resolveIcon = (type: MessageType): FASemanticICONS | "notched circle loading" | null => {
    switch (type) {
        case "loading":
            return "notched circle loading";
        case "error":
            return "exclamation circle";
        case "info":
            return "info circle";
        case "warning":
            return "exclamation triangle";
        case "success":
            return "check circle";
        default:
            return null;
    }
};

const renderMessage = (message: string | string[] | React.ReactNode): React.ReactNode => {
    if (!Array.isArray(message)) {
        return message;
    }

    if (message.length === 1) {
        return message[0];
    }

    return (
        <ul className="ui list">
            {message.map((c: string | string[] | React.ReactNode, i: number) => (
                <li key={i}>
                    {c}
                </li>
            ))}
        </ul>
    );
};

export const InlineMessage: React.FC<IProps> = ({
    message,
    type,
    icon,
    className,
    compact = false,
    children,
}) => {
    if (children == null && (message == null || (Array.isArray(message) && message.length <= 0))) {
        return null;
    }

    let resolvedIcon = icon ?? resolveIcon(type ?? "neutral");
    if (resolvedIcon === "hidden") {
        resolvedIcon = null;
    }

    return (
        <div
            className={classNames(
                type,
                "ui message icon",
                { compact, visible: !compact, [styles.withIcon]: resolvedIcon != null },
                className,
                styles.message
            )}
        >
            {resolvedIcon != null && (
                <Icon name={resolvedIcon as SemanticICONS} />
            )}
            {children ?? renderMessage(message)}
        </div>
    );
};
