export class AccessTokenUtility {

    /**
     * The name of the session variable that contains the access token.
     */
    private static readonly accessTokenItemName: string = "AccessToken";

    public static setToken(token: string | null): void {
        if (token != null) {
            sessionStorage.setItem(AccessTokenUtility.accessTokenItemName, token);
        } else {
            sessionStorage.removeItem(AccessTokenUtility.accessTokenItemName);
        }
    }

    /**
     * Get the access token from the cookies.
     */
    public static getToken(): string | null {
        return sessionStorage.getItem(AccessTokenUtility.accessTokenItemName);
    }
}
export default AccessTokenUtility;
