import React from "react";
import { AuthenticationCondition, useAuthenticationContext } from "@/auth";

export const ContentPage: React.FC = (props) => {
    const { isAuthenticated, isLoading, signIn } = useAuthenticationContext();

    // Redirect to the login page when not signed in properly.
    React.useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            signIn();
        }
    }, [isLoading, isAuthenticated])

    return (
        <AuthenticationCondition authenticated={true}>
            {props.children}
        </AuthenticationCondition>
    );
};
