import { useMemo } from "react";

/**
 * Only match the first part of the path name
 * /organization-unavailable                    => organization-unavailable
 * /organization-unavailable?w=hello            => organization-unavailable
 * /organization-unavailable/hi?w=hello         => organization-unavailable
 */
const defaultPattern = /(?<=\/)[^/?]+/i;

type ReverseRouter = {
    path: string
}

export const useReverseRouter: () => ReverseRouter = () => {
    const path = useMemo(() => {
        const defaultRegex = defaultPattern.exec(window.location.pathname);
        return defaultRegex != null
            ? defaultRegex[0]
            : window.location.pathname
    }, [window.location.pathname]);

    return { path }
};
