import * as React from "react";

// Load the player async
const Player = React.lazy(() => import(
    "@lottiefiles/react-lottie-player").then((b) => ({ default: b.Player }))
);

interface IProps {
    src: string;
    behavior?: "once" | "loop" | "lastFrame"
}

export const Animation: React.FC<IProps> = ({
    src,
    behavior = "loop"
}) => (
    <Player
        src={src}
        autoplay={behavior === "once" || behavior === "loop"}
        lottieRef={(a) => (
            behavior === "lastFrame"
            && a.goToAndStop(a.totalFrames, true)
        )}
        loop={behavior === "loop"}
        keepLastFrame={behavior === "once"}
    />
);
