type RequestIdleCallbackHandle = unknown;
type RequestIdleCallbackOptions = {
    timeout: number;
};
type RequestIdleCallbackDeadline = {
    readonly didTimeout: boolean;
    timeRemaining: (() => number);
};

declare global {
    interface Window {
        requestIdleCallback: ((
            callback: ((deadline: RequestIdleCallbackDeadline) => void),
            opts?: RequestIdleCallbackOptions,
        ) => RequestIdleCallbackHandle);
        cancelIdleCallback: ((handle: RequestIdleCallbackHandle) => void);
    }
}

class Timer {
    private constructor(private clearAction: () => void) { }

    public stop(): void {
        this.clearAction();
    }

    public static scheduleIdle(action: () => void, maxTimeout: number): Timer {
        if ("requestIdleCallback" in window) {
            const handle = window.requestIdleCallback(action, { timeout: maxTimeout });
            return new Timer(() => window.cancelIdleCallback(handle));
        }

        return Timer.scheduleOnce(action, maxTimeout);
    }

    public static scheduleOnce(action: () => void, timeout: number): Timer {
        const handle = window.setTimeout(action, timeout);
        return new Timer(() => window.clearTimeout(handle));
    }

    public static scheduleRepeat(action: () => void, timeout: number): Timer {
        const handle = window.setInterval(action, timeout);
        return new Timer(() => window.clearInterval(handle));
    }
}
export default Timer;
