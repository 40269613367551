import React from "react";
import { useLoggingContext } from "@/contexts";
import { InlineMessage } from "@/components/common/InlineMessage";

export const LoggingPlaceholder: React.FC = () => {
    const loggingContext = useLoggingContext();
    return (
        <>
            {loggingContext.errors.map((error, index) => (
                <InlineMessage
                    key={index}
                    message={error}
                    type="error"
                />
            ))}
        </>
    );
};
