import createAuth0Client, { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";
import IAuthenticationClient, { AuthenticationResult } from "./IAuthenticationClient";
import { urlUtility } from "@/utils";

class Auth0AuthenticationClient implements IAuthenticationClient {
    private auth0Client: Auth0Client | null = null;

    public constructor(private config: Auth0ClientOptions) {
    }

    public get isResumingSession(): boolean {
        return document.cookie.match(/(?:^|; )auth0.is.authenticated=true(?:;|$)/) != null;
    }

    public initialize = async (): Promise<AuthenticationResult> => {
        const auth0FromHook = await createAuth0Client(this.config);
        this.auth0Client = auth0FromHook;

        if (window.location.search.includes("code=") &&
            window.location.search.includes("state=")) {
            const { appState } = await auth0FromHook.handleRedirectCallback();

            window.history.replaceState({}, "", appState.returnTo);
        }

        const authenticated = await auth0FromHook.isAuthenticated();

        if (authenticated) {
            const auth0User = await auth0FromHook.getUser();
            if (auth0User != null) {
                // Use the ID token instead the authorization token. This contains user data.
                const token = (await auth0FromHook.getIdTokenClaims()).__raw;
                return {
                    isAuthenticated: true,
                    token: "Bearer " + token,
                    user: {
                        firstName: auth0User.given_name ?? "",
                        pictureUrl: auth0User.picture ?? "",
                        defaultTenant: (auth0User["default_portal"] as string) ?? null,
                        activeTenants: auth0User["https://claims.keeyns.com/active_tenants"] as string[],
                    },
                };
            }
        }
        return {
            isAuthenticated: false,
            token: null,
            user: null,
        };
    }

    public openSignIn = (): Promise<void> => {
        if (this.auth0Client == null) {
            throw Error("Need to call initialize() first.");
        }

        return this.auth0Client.loginWithRedirect({
            appState: {
                returnTo: urlUtility.getPathAndQuery()
            }
        });
    }

    public signOut = (): Promise<void> => {
        if (this.auth0Client == null) {
            throw Error("Need to call initialize() first.");
        }

        this.auth0Client.logout({
            returnTo: location.origin,
        });

        return Promise.resolve();
    }
}
export default Auth0AuthenticationClient;
