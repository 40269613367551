import config from "@/auth/config";
import { PortalDto } from "@/services/entities";
import { useApi, UseApiResult } from "@/utils";

type UseAthenaApiHook = () => {
    portals: UseApiResult<PortalDto>;
};

export const useAthenaApi: UseAthenaApiHook = () => {
    const portalsApi = useApi<PortalDto>(config.athenaUrl + "/api/portals/mine");
    return {
        portals: portalsApi,
    };
};
