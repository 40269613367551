import * as React from "react";
import { useAuthenticationContext } from "./AuthenticationProvider";

export const AuthenticationCondition: React.FC<{ authenticated?: boolean, loading?: boolean }> = (props) => {
    const { isAuthenticated, isLoading } = useAuthenticationContext();
    if ((props.authenticated == null || isAuthenticated === props.authenticated) && (props.loading == null || isLoading === props.loading)) {
        return (<>{props.children}</>);
    } else {
        return null;
    }
};
