import { useAuthenticationContext } from "@/auth";
import { EmptyStatesComponent, IconButton } from "@/components/common";
import { PortalList } from "@/components/portalList";
import { useLoadingContext } from "@/contexts";
import { useAthenaApi } from "@/services";
import { PortalDto } from "@/services/entities";
import { useChangeTimeout, usePromise } from "@/utils";
import React from "react";
import { Input } from "semantic-ui-react";

import styles from "./css/Welcome.scss";

export const Welcome: React.FC = () => {
    const [filterConcept, setFilterConcept] = React.useState("");
    const [filter, setFilter] = React.useState("");

    const loadingContext = useLoadingContext();
    const authContext = useAuthenticationContext();
    const api = useAthenaApi();
    const [listPortalsStatus, listPortals] = usePromise(api.portals.list, { onSuccessful: (result) => result.sort((a, b) => a.displayName.localeCompare(b.displayName)) });
    const [selectedPortalUrl, setSelectedPortalUrl] = React.useState<string | null>(null);
    React.useEffect(listPortals, []);

    React.useEffect(() => {
        // Automatically open the first item from the list
        if (listPortalsStatus.isSuccess && listPortalsStatus.result.length === 1) {
            window.location.replace(listPortalsStatus.result[0].url);
        }
    }, [listPortalsStatus]);

    const portals: PortalDto[] = React.useMemo(() => {
        if (!listPortalsStatus.isSuccess) {
            return [];
        }

        if (!filter) {
            return listPortalsStatus.result;
        }
        return listPortalsStatus.result.filter((portal) => portal.displayName.toLowerCase().includes(filter) || portal.url.toLowerCase().includes(filter));
    }, [filter, listPortalsStatus]);

    React.useEffect(() => {
        loadingContext.setIsLoading(
            listPortalsStatus.neverRun
            || listPortalsStatus.isRunning
            || listPortalsStatus.result?.length === 1
        );
        if (selectedPortalUrl != null) {
            window.location.href = selectedPortalUrl;
        }
    }, [listPortalsStatus.isRunning, listPortalsStatus.result, selectedPortalUrl]);

    useChangeTimeout(() => {
        setFilter(filterConcept.toLowerCase());
    }, [filterConcept]);

    const onEnterPressed = React.useCallback(() => {
        if (portals.length === 1) {
            setSelectedPortalUrl(portals[0].url);
        }
    }, [portals]);

    if (loadingContext.isLoading) {
        return null;
    }

    return (
        <div>
            <div className={styles.welcome}>
                <span className={styles.signOutButton}>
                    <IconButton
                        icon="fa-sign-out"
                        toolTip="Sign out"
                        onClick={authContext.signOut}
                        plain={true}
                        disabled={selectedPortalUrl != null}
                    />
                </span>
                <h2>
                    {authContext.user?.firstName != null ? `Welcome ${authContext.user.firstName}!` : "Welcome!"}
                </h2>
                <>
                    <p>
                        What organization do you want to enter?
                    </p>
                    <div>
                        <Input
                            className={styles.filter}
                            placeholder="Search"
                            icon="search"
                            autoFocus={true}
                            value={filterConcept}
                            fluid={true}
                            onChange={(e, d) => setFilterConcept(d.value)}
                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.charCode === 13 && onEnterPressed()}
                        />
                    </div>
                    <div>
                        <p>Your organizations ({portals.length})</p>
                        <PortalList
                            portals={portals}
                            setSelectedPortalUrl={setSelectedPortalUrl}
                        />
                        {portals.length <= 0 && (
                            <EmptyStatesComponent
                                imgPath={filter.length > 0 ? "empty_question_mark" : "empty_nothing_here"}
                                title={
                                    filter.length > 0
                                        ? "Oops! No organizations found!"
                                        : "There are no organizations here..."
                                }
                            />
                        )}
                    </div>
                </>
            </div>
        </div>
    );
};
