import { FASemanticICONS } from "@/types";
import classNames from "classnames";
import * as React from "react";
import { Button, Icon, SemanticICONS } from "semantic-ui-react";

import styles from "./css/IconButton.scss";

interface IProps {
    onClick: () => void;
    toolTip: string;
    icon: FASemanticICONS;
    plain?: boolean;
    size?: "sm" | "md" | "lg";
    disabled?: boolean;
}

export const IconButton: React.FC<IProps> = (props) => (
    <div
        data-tooltip={props.toolTip}
        data-position="top center"
    >
        <Button
            disabled={props.disabled === true}
            className={classNames(
                styles.iconButton,
                {
                    plain: props.plain === true,
                    [styles.sm]: props.size === "sm" || props.size == null,
                    [styles.md]: props.size === "md",
                    [styles.lg]: props.size === "lg",
                }
            )}
            compact={true}
            icon={
                <Icon
                    onClick={props.onClick}
                    className="clickable"
                    name={props.icon as SemanticICONS}
                />
            }
        >
        </Button>
    </div>
)
