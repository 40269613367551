import * as React from "react";
import Timer from "./Timer";

type Action = () => void;
export const useInterval = (callback: Action, delay: number, isEnabled: boolean): void => {
    const savedCallback = React.useRef<Action>();

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current != null) {
                savedCallback.current();
            }
        }

        if (isEnabled) {
            const timer = Timer.scheduleRepeat(tick, delay);
            return () => {
                timer.stop();
            };
        }
    }, [delay, isEnabled]);
};

export const useChangeTimeout = (callback: Action, deps: readonly unknown[]): void => {
    const delay = 300;

    const savedCallback = React.useRef<Action>();

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current != null) {
                savedCallback.current();
            }
        }

        const timer = Timer.scheduleOnce(tick, delay);
        return () => {
            timer.stop();
        };
    }, [delay, ...deps]);
};

export const useIdleTimeout = (callback: Action, deps?: readonly unknown[]): void => {
    const delay = 2000;
    deps = deps || [];

    const savedCallback = React.useRef<Action>();

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current != null) {
                savedCallback.current();
            }
        }

        const timer = Timer.scheduleIdle(tick, delay);
        return () => {
            timer.stop();
        };
    }, [delay, ...deps]);
};

export const useTimeout = (callback: Action, delay: number, isEnabled: boolean, deps?: readonly unknown[]): void => {
    deps = deps || [];

    const savedCallback = React.useRef<Action>();

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current != null) {
                savedCallback.current();
            }
        }

        if (isEnabled) {
            const timer = Timer.scheduleOnce(tick, delay);
            return () => {
                timer.stop();
            };
        }
    }, [delay, isEnabled, ...deps]);
};
