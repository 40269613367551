import { LoggingProvider } from "@/contexts";
import React from "react";

import styles from "./css/PageLayout.scss";
interface IProps {
    children?: React.ReactNode;
}

export const EmptyPageLayout: React.FC<IProps> = (props) => (
    <LoggingProvider>
        <div className={styles.emptyBody}>
            {props.children}
        </div>
    </LoggingProvider>
);
