import * as React from "react";
import constate from "constate";

const useLogging = () => {
    const [errors, setErrors] = React.useState<string[]>([]);

    let errorCollection = errors;

    const writeError = (message: string) => {
        errorCollection = [...errorCollection, message];
        setErrors(errorCollection.filter((item, index) => errorCollection.indexOf(item) === index));
    };

    const clearAll = () => {
        if (errorCollection.length === 0) {
            return;
        }

        errorCollection = [];
        setErrors(errorCollection);
    };

    const removeError = (index: number) => {
        const clone = [...errorCollection];
        clone.splice(index, 1);
        setErrors(clone);
    };

    return {
        writeError,
        removeError,
        clearAll,
        errors,
    };
};

export const [LoggingProvider, useLoggingContext] = constate(useLogging);

export const withLoggingBoundary = <P extends object>(Component: React.FC<P>): React.FC<P> => (props: P) => {
    const LoggingHocComponent = (
        <LoggingProvider>
            <Component {...props} />
        </LoggingProvider>
    );
    return LoggingHocComponent;
};
