import { ApplicationLoaderPage, EmptyPageLayout } from "@/pages";
import constate from "constate";
import * as React from "react";

const useLoading = () => {
    const [isLoading, setIsLoading] = React.useState(true);

    return {
        isLoading,
        setIsLoading,
    };
};
const [LoadingContextProvider, useLoadingContext] = constate(useLoading);

// This loading component will render itself and it's children.
// Rendering the children is needed as these components will finally disable this loading screen.
const Component: React.FC = (props) => {
    const loadingContext = useLoadingContext();
    return (
        <>
            {loadingContext.isLoading && (
                <EmptyPageLayout>
                    <ApplicationLoaderPage />
                </EmptyPageLayout>
            )}
            {props.children}
        </>
    );
};

// A component with the context provider and the component that will be rendered/
const Page: React.FC = (props) => (
    <LoadingContextProvider>
        <Component>{props.children}</Component>
    </LoadingContextProvider>
);

export { Page as LoadingProvider, useLoadingContext };
